import React from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import { Tooltip } from 'react-tooltip';
import { Participant } from '../../utils/httpRequests';

interface StudentInfoModalProps {
  isOpen: boolean;
  isClosing: boolean;
  selectedParticipant: Participant | null;
  onClose: () => void;
  generateTemporaryPassword: () => Promise<void>;
  copyToClipboard: () => void;
  passwordSet: boolean;
  error: boolean;
  selectedTemporaryPassword: string;
  passwordCopied: string;
  updateStudentInfo: () => void;
  handleCancelEdit: () => void;
  deleteParticipant: (participant: Participant) => void;
  resetAvatar: (participant: Participant) => void;
  deleteLoading: boolean;
  resetLoading: boolean;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  editedFirstName: string;
  editedLastName: string;
  editedEmail: string;
  editedUsername: string;
  setEditedFirstName: React.Dispatch<React.SetStateAction<string>>;
  setEditedLastName: React.Dispatch<React.SetStateAction<string>>;
  setEditedEmail: React.Dispatch<React.SetStateAction<string>>;
  setEditedUsername: React.Dispatch<React.SetStateAction<string>>;
}

const StudentInfoModal: React.FC<StudentInfoModalProps> = ({
  isOpen,
  isClosing,
  selectedParticipant,
  onClose,
  generateTemporaryPassword,
  copyToClipboard,
  passwordSet,
  error,
  selectedTemporaryPassword,
  passwordCopied,
  updateStudentInfo,
  handleCancelEdit,
  deleteParticipant,
  resetAvatar,
  deleteLoading,
  resetLoading,
  isEditing,
  setIsEditing,
  editedFirstName,
  editedLastName,
  editedEmail,
  editedUsername,
  setEditedFirstName,
  setEditedLastName,
  setEditedEmail,
  setEditedUsername,
}) => {
  const { t } = useTranslation('global');

  // If the modal isn't open or closing, render nothing
  if (!isOpen && !isClosing) return null;

  return (
    <>
      {/* Backdrop */}
      <div className='modal-backdrop show' style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}></div>

      {/* Modal */}
      <div
        className={`modal fade info-modal ${isOpen ? 'show' : ''} ${
          isClosing ? 'modal-drop-out' : 'modal-drop-in'
        }`}
        tabIndex={-1}
        aria-hidden='true'
        style={{ display: isOpen || isClosing ? 'block' : 'none' }}
        role={isOpen ? 'dialog' : ''}
      >
        <div className='modal-dialog modal-lg modal-dialog-centered'>
          <div className='modal-content user-card-full'>
            {/* Left side (avatar + name) */}
            <div className='row g-0'>
              <div className='col-sm-4 user-profile'>
                <div className='text-center text-white p-3 d-flex flex-column align-items-center justify-content-center h-100'>
                  <img
                    src='https://img.icons8.com/bubbles/100/000000/user.png'
                    className='img-radius mb-3'
                    alt='User-Profile-Image'
                  />
                  <h6 className='f-w-600'>
                    {selectedParticipant?.user.firstName} {selectedParticipant?.user.lastName}
                  </h6>
                </div>
              </div>

              {/* Right side (details + actions) */}
              <div className='col-sm-8'>
                <div className='card-block p-4 position-relative'>
                  {/* Close Button */}
                  <button
                    type='button'
                    className='btn btn-close close-modal'
                    onClick={onClose}
                    aria-label='Close'
                  ></button>

                  {/* INFORMATION SECTION */}
                  <h6 className='modal-section-title'>{t('information')}</h6>
                  <div className='row mb-3'>
                    {/* First Name */}
                    <div className='col-sm-6 mb-3'>
                      <label className='modal-label'>{t('firstName')}</label>
                      {isEditing ? (
                        <input
                          type='text'
                          value={editedFirstName}
                          onChange={(e) => setEditedFirstName(e.target.value)}
                          className='form-control'
                        />
                      ) : (
                        <p className='text-muted'>{selectedParticipant?.user.firstName}</p>
                      )}
                    </div>
                    {/* Last Name */}
                    <div className='col-sm-6 mb-3'>
                      <label className='modal-label'>{t('lastName')}</label>
                      {isEditing ? (
                        <input
                          type='text'
                          value={editedLastName}
                          onChange={(e) => setEditedLastName(e.target.value)}
                          className='form-control'
                        />
                      ) : (
                        <p className='text-muted'>{selectedParticipant?.user.lastName}</p>
                      )}
                    </div>
                  </div>

                  <div className='row mb-3'>
                    {/* Email */}
                    <div className='col-sm-6 mb-3'>
                      <label className='modal-label'>{t('email')}</label>
                      {isEditing ? (
                        <input
                          type='email'
                          value={editedEmail}
                          onChange={(e) => setEditedEmail(e.target.value)}
                          className='form-control'
                        />
                      ) : (
                        <p className='text-muted'>{selectedParticipant?.user.email}</p>
                      )}
                    </div>
                    {/* Username */}
                    <div className='col-sm-6 mb-3'>
                      <label className='modal-label'>{t('username')}</label>
                      {isEditing ? (
                        <input
                          type='text'
                          value={editedUsername}
                          onChange={(e) => setEditedUsername(e.target.value)}
                          className='form-control'
                        />
                      ) : (
                        <p className='text-muted'>{selectedParticipant?.user.username}</p>
                      )}
                    </div>
                  </div>

                  {/* Edit / Save Buttons */}
                  <div className='mb-4'>
                    {isEditing ? (
                      <>
                        <button className='btn btn-success me-2' onClick={updateStudentInfo}>
                          {t('save')}
                        </button>
                        <button className='btn btn-secondary' onClick={handleCancelEdit}>
                          {t('cancel')}
                        </button>
                      </>
                    ) : (
                      <button
                        className='btn btn-primary'
                        onClick={() => {
                          setIsEditing(true);
                        }}
                      >
                        {t('editInfo')}
                      </button>
                    )}
                  </div>

                  {/* PASSWORD SECTION */}
                  <h6 className='modal-section-title'>{t('setNewPassword')}</h6>
                  <div className='row mb-4'>
                    <div className='col-sm-6 d-flex flex-column'>
                      {passwordSet ? (
                        error ? (
                          <div className='alert alert-danger p-2' role='alert'>
                            {t('newTempPasswordError')}
                          </div>
                        ) : (
                          <>
                            <label className='modal-label'>{t('newPassword')}</label>
                            <a data-tooltip-id='my-tooltip' data-tooltip-content={passwordCopied}>
                              <p
                                className='text-muted pointer mb-0'
                                data-testid='copyToClipboard'
                                onClick={copyToClipboard}
                              >
                                {selectedTemporaryPassword}
                              </p>
                            </a>
                            <Tooltip id='my-tooltip' />
                          </>
                        )
                      ) : (
                        <>
                          <label className='modal-label'>{t('password')}</label>
                          <p className='text-muted pointer mb-0'>********</p>
                        </>
                      )}
                    </div>
                    <div className='col-sm-6 d-flex align-items-center'>
                      <button
                        className='btn btn-primary btn-sm'
                        data-testid='generateNewPassword'
                        onClick={generateTemporaryPassword}
                      >
                        {t('genNewPassword')}
                      </button>
                    </div>
                  </div>

                  {/* RESET AVATAR SECTION */}
                  <h6 className='modal-section-title'>{t('resetAvatar')}</h6>
                  <div className='mb-4'>
                    {resetLoading ? (
                      <Loading insideModal='coursePage' />
                    ) : (
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => {
                          if (selectedParticipant) resetAvatar(selectedParticipant);
                        }}
                      >
                        {t('resetAvatar')}
                      </button>
                    )}
                  </div>

                  {/* REMOVE PARTICIPANT SECTION */}
                  <h6 className='modal-section-title'>{t('removeParticipant')}</h6>
                  <div>
                    {deleteLoading ? (
                      <Loading insideModal='coursePage' />
                    ) : (
                      <button
                        type='button'
                        className='btn btn-danger'
                        data-testid='removeParticipant'
                        onClick={() => {
                          if (selectedParticipant) deleteParticipant(selectedParticipant);
                        }}
                      >
                        {t('removeParticipant')}
                      </button>
                    )}
                  </div>
                </div>
                {/* card-block */}
              </div>
              {/* col-sm-8 */}
            </div>
            {/* row */}
          </div>
          {/* modal-content */}
        </div>
        {/* modal-dialog */}
      </div>
      {/* modal */}
    </>
  );
};

export default StudentInfoModal;
