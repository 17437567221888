import { useState } from 'react';
import CreateQuestModal from './modals/CreateQuestModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Removed 'faEye' since it's not used
import { faPencil, faPlus, faTrash, faCogs } from '@fortawesome/free-solid-svg-icons';
import { fetchAPI } from '../../../utils/httpRequests';
import { useQuery } from 'react-query';
import RenderUnsafeHTML from '../../../components/RenderUnsafeHTML';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { QuizQuestionForm } from '../../../components/quiz/QuizQuestion';
import { User } from '../../../utils/auth';
import { ConceptFileSubmissionTypes } from '../concepts/ConceptModal';
import Loading from '../../../components/Loading';

export interface Course {
  id: number;
  name: string;
  accessCode: string;
  accessCodeBlocked: boolean;
}

interface BaseStory {
  id: number;
  title: string;
  setting: string;
  epilogue: string;
  order: number;
  storyOrder: number;
}

interface StoryWithDifferentiation extends BaseStory {
  hasDifferentiation: true;
  differentiationSetting: string;
}

interface StoryWithoutDifferentiation extends BaseStory {
  hasDifferentiation: false;
}

export type Story = StoryWithDifferentiation | StoryWithoutDifferentiation;

export interface MasterStory {
  id: number;
  user: User;
  introduction: string;
  conclusion: string;
  name: string;
  stories: Story[];
}

interface BaseConcept {
  id: number;
  title: string;
  learning: string;
  practice: string;
  conceptQuestions: QuizQuestionForm[];
  fileSubmissionType: ConceptFileSubmissionTypes;
}

interface ConceptWithDifferentiation extends BaseConcept {
  hasDifferentiation: true;
  differentiationLearning: string;
  differentiationPractice: string;
}

interface ConceptWithoutDifferentiation extends BaseConcept {
  hasDifferentiation: false;
}

export type Concept = ConceptWithDifferentiation | ConceptWithoutDifferentiation;

export interface QuestStep {
  id: number;
  positionX: number;
  positionY: number;
  dueDate: string;
  rewardXp: number;
  rewardGold: number;
  stepOrder: number;
  concept: Concept;
}

interface QuestCourse {
  id: number;
  name: string;
}

export interface Quest {
  id: number;
  name: string;
  introduction: string;
  questSteps: QuestStep[];
  masterStory: MasterStory;
  courses: QuestCourse[];
}

export type QuestLogStatus =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'COMPLETED_WITH_DIFFERENTIATION'
  | 'FAILED'
  | 'WAITING_ON_VALIDATION'
  | 'START'
  | 'END';

export interface QuestStepLog {
  questStepId: number;
  status: QuestLogStatus;
}

export interface QuestLog {
  questId: number;
  status: QuestLogStatus;
  questStepLogResponses: QuestStepLog[];
}

const Quests = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const [createModalOpen, setCreateModalOpen] = useState(false); // Controls the state of the modal that creates quests
  const [editQuest, setEditQuest] = useState<Quest | null>(null); // Contains the quest that will be edited

  // Get all quests
  const fetchQuests = async (): Promise<Quest[] | null> => {
    const quests = await fetchAPI<Quest[]>('/quests');
    return quests.isSuccess ? quests.data : null;
  };

  // Query to fetch quests
  const { data: quests, isLoading, refetch } = useQuery('quests', fetchQuests);

  // Delete a quest
  const deleteQuest = async (quest: Quest) => {
    const response = await fetchAPI(`/quests/${quest.id}`, {
      method: 'DELETE',
    });
    if (response.isSuccess) {
      await refetch();
    }
  };

  // Close modal helper
  const closeModal = async (id?: number | null) => {
    setCreateModalOpen(false);
    setEditQuest(null);
    if (id) {
      navigate(`/quests/${id}`);
    }
  };

  // Update quest helper
  const updateQuest = (quest: Quest) => {
    setEditQuest(quest);
    setCreateModalOpen(true);
  };

  return (
    <>
      <div className='px-md-5 py-3' data-testid='quests'>
        <h2>{t('quests.title')}</h2>
        <button className='btn btn-primary mb-2' onClick={() => setCreateModalOpen(true)}>
          <FontAwesomeIcon icon={faPlus} className='me-1' />
          {t('quests.create_button')}
        </button>
        <div>
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              {/* Display all the quests linked with the logged-in teacher */}
              {quests?.map((questItem) => (
                <div className='card my-2' key={questItem.id}>
                  <div className='card-body'>
                    <h5 className='card-title'>{questItem.name}</h5>
                    <p className='card-text'>
                      <RenderUnsafeHTML html={questItem.introduction} />
                    </p>
                    <div className='d-flex gap-2'>
                      {/* "Manage" button uses faCogs */}
                      <Link to={`/quests/${questItem.id}`} className='btn btn-outline-primary'>
                        <FontAwesomeIcon icon={faCogs} className='me-2' />
                        {t('manage')}
                      </Link>

                      {/* "Edit" button with faPencil */}
                      <button
                        className='btn btn-primary'
                        type='button'
                        onClick={() => updateQuest(questItem)}
                      >
                        <FontAwesomeIcon icon={faPencil} className='me-2' />
                        {t('edit')}
                      </button>

                      {/* "Delete" button with faTrash */}
                      <button
                        className='btn btn-danger'
                        type='button'
                        onClick={() => deleteQuest(questItem)}
                      >
                        <FontAwesomeIcon icon={faTrash} className='me-2' />
                        {t('delete')}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* The modal for creating/editing a quest */}
      <CreateQuestModal isOpen={createModalOpen} onClose={closeModal} editQuest={editQuest} />
    </>
  );
};

export default Quests;
