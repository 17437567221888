import { fetchAPI, UpdateAvatar, updateAvatar, Participant } from './httpRequests';
import { TFunction } from 'i18next';

export const deleteParticipantAction = async (
  participant: Participant,
  t: TFunction,
  refetch: () => Promise<void>,
  handleModalClose: () => void,
  setDeleteLoading: (value: boolean) => void,
) => {
  setDeleteLoading(true);
  const confirmation = confirm(
    t('confirmRemoveParticipant', {
      firstName: participant.user.firstName,
      lastName: participant.user.lastName,
    }),
  );
  if (confirmation) {
    const response = await fetchAPI(`/participants/${participant.id}`, {
      method: 'DELETE',
    });
    if (response.isSuccess) {
      handleModalClose();
      await refetch();
    }
  }
  setDeleteLoading(false);
};

export const resetAvatarAction = async (
  participant: Participant,
  setResetLoading: (value: boolean) => void,
) => {
  setResetLoading(true);
  const resetData: UpdateAvatar = {
    id: participant.id,
    avatar: '',
    avatarName: '',
  };
  const start = Date.now();
  await updateAvatar(resetData);
  const elapsed = Date.now() - start;
  const minDuration = 500;
  if (elapsed < minDuration) {
    await new Promise<void>((resolve) => setTimeout(resolve, minDuration - elapsed));
  }
  setResetLoading(false);
};
