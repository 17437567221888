import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { fetchAPI } from '../../../utils/httpRequests';
import { Concept, MasterStory, Story } from './Quests';
import Loading from '../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faLayerGroup, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
// Adjust these import paths if your modal files are located elsewhere:
import ConceptModal from '../concepts/ConceptModal';
import MasterStoryModal from '../stories/MasterStoryModal';

const QuestSidebar = () => {
  const { t } = useTranslation('global');

  // Local state for modals and error message
  const [createConceptModalOpen, setCreateConceptModalOpen] = useState(false);
  const [editConcept, setEditConcept] = useState<Concept | null>(null);
  const [createMasterModalOpen, setCreateMasterModalOpen] = useState(false);
  const [editMasterStory, setEditMasterStory] = useState<MasterStory | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Fetch concepts
  const fetchConcepts = async (): Promise<Concept[] | null> => {
    const response = await fetchAPI<Concept[]>(`/concepts`);
    return response.isSuccess ? response.data : null;
  };

  // Fetch master stories (including stories)
  const fetchMasterStories = async (): Promise<MasterStory[] | null> => {
    const response = await fetchAPI<MasterStory[]>(`/master-stories`);
    return response.isSuccess ? response.data : null;
  };

  const {
    data: concepts,
    isLoading: conceptsLoading,
    refetch: refetchConcepts,
  } = useQuery('concepts', fetchConcepts);
  const {
    data: masterStories,
    isLoading: storiesLoading,
    refetch: refetchMasterStories,
  } = useQuery('masterStories', fetchMasterStories);

  if (conceptsLoading || storiesLoading) {
    return <Loading />;
  }

  // ----- Concepts Handlers -----
  const updateConcept = (concept: Concept) => {
    // Use the same logic as the ConceptsPage: open the modal with the concept data.
    setEditConcept(concept);
    setCreateConceptModalOpen(true);
  };

  const closeConceptModal = async (): Promise<void> => {
    await refetchConcepts();
    setCreateConceptModalOpen(false);
    setEditConcept(null);
  };

  const deleteConcept = async (concept: Concept) => {
    const response = await fetchAPI(`/concepts/${concept.id}`, {
      method: 'DELETE',
    });
    if (response.isSuccess) {
      await refetchConcepts();
      setErrorMessage(null);
    } else {
      setErrorMessage(t('concept.cannot_delete_in_use'));
    }
  };

  // ----- Master Stories Handlers -----
  const updateMasterStory = (masterStory: MasterStory) => {
    // Use the same logic as the StoriesPage: open the modal with the master story data.
    setEditMasterStory(masterStory);
    setCreateMasterModalOpen(true);
  };

  const closeMasterModal = async (): Promise<void> => {
    await refetchMasterStories();
    setCreateMasterModalOpen(false);
    setEditMasterStory(null);
  };

  const deleteMasterStory = async (masterStory: MasterStory) => {
    const response = await fetchAPI(`/master-stories/${masterStory.id}`, {
      method: 'DELETE',
    });
    if (response.isSuccess) {
      await refetchMasterStories();
      setErrorMessage(null);
    } else {
      setErrorMessage(t('master_story.cannot_delete_in_use'));
    }
  };

  return (
    <div className='bg-light shadow-sm rounded p-3' style={{ width: '300px', overflowY: 'auto' }}>
      {errorMessage && (
        <div className='alert alert-danger alert-dismissible fade show' role='alert'>
          {errorMessage}
          <button
            type='button'
            className='btn-close'
            onClick={() => setErrorMessage(null)}
            aria-label='Close'
          />
        </div>
      )}

      {/* Concepts Section */}
      <div className='mb-4'>
        <Link to='/concepts' className='text-decoration-none'>
          <h4 className='d-flex align-items-center text-primary'>
            <FontAwesomeIcon icon={faBook} className='me-2' />
            {t('concepts.page')}
          </h4>
        </Link>
        <ul className='list-group'>
          {concepts?.map((concept) => (
            <li
              key={concept.id}
              className='list-group-item d-flex justify-content-between align-items-center'
            >
              <span>{concept.title}</span>
              <span>
                <button
                  className='btn btn-sm btn-primary me-1'
                  onClick={() => updateConcept(concept)}
                >
                  <FontAwesomeIcon icon={faPencil} />
                </button>
                <button className='btn btn-sm btn-danger' onClick={() => deleteConcept(concept)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </span>
            </li>
          ))}
        </ul>
      </div>

      {/* Master Stories Section */}
      <div>
        <Link to='/stories' className='text-decoration-none'>
          <h4 className='d-flex align-items-center text-primary'>
            <FontAwesomeIcon icon={faLayerGroup} className='me-2' />
            {t('master_stories.page')}
          </h4>
        </Link>
        {masterStories?.map((masterStory) => (
          <div key={masterStory.id} className='mb-3'>
            <div className='d-flex justify-content-between align-items-center'>
              <h5 className='mb-0'>{masterStory.name}</h5>
              <span>
                <button
                  className='btn btn-sm btn-primary me-1'
                  onClick={() => updateMasterStory(masterStory)}
                >
                  <FontAwesomeIcon icon={faPencil} />
                </button>
                <button
                  className='btn btn-sm btn-danger'
                  onClick={() => deleteMasterStory(masterStory)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </span>
            </div>
            <ul className='list-group'>
              {masterStory.stories?.map((story: Story) => (
                <li key={story.id} className='list-group-item'>
                  {story.title}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Render the modals for editing */}
      {createConceptModalOpen && (
        <ConceptModal
          isOpen={createConceptModalOpen}
          onClose={closeConceptModal}
          editConcept={editConcept}
        />
      )}
      {createMasterModalOpen && (
        <MasterStoryModal
          isOpen={createMasterModalOpen}
          onClose={closeMasterModal}
          editMasterStory={editMasterStory}
        />
      )}
    </div>
  );
};

export default QuestSidebar;
