import { useQuery } from 'react-query';
import { fetchAPI } from '../../utils/httpRequests';
import { Course } from '../teacher/quests/Quests';
import { useAuth } from '../../utils/stores';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import CourseCard from '../teacher/cards/CourseCard';
import AvatarCard from '../student/cards/AvatarCard';
import './Welcome.css';

interface Avatar {
  avatar: string;
  avatarName: string | null;
  code: string;
  id: number;
  role: string;
  course: {
    id: number;
    name: string;
    xpPerLevel: number;
    teacher?: {
      firstName: string;
      lastName: string;
    };
  };
  xp: number;
  gold: number;
  life: number;
}

interface Participant {
  id: number;
  avatar: string;
  user: {
    firstName: string;
    lastName: string;
  };
  role: string;
}

interface CourseWithParticipants extends Course {
  participants?: Participant[];
}

const Welcome = () => {
  const { t } = useTranslation('global');
  const { user, signedIn } = useAuth();

  const fetchClasses = async (): Promise<CourseWithParticipants[] | null> => {
    const classes = await fetchAPI<CourseWithParticipants[]>('/courses');
    return classes.isSuccess ? classes.data : null;
  };

  const fetchAvatars = async (): Promise<Avatar[] | null> => {
    const avatars = await fetchAPI<Avatar[]>('/participants');
    return avatars.isSuccess ? avatars.data : null;
  };

  const { data: classes, isLoading: classesLoading } = useQuery('courses', fetchClasses);
  const { data: avatars, isLoading: avatarsLoading } = useQuery('participants', fetchAvatars);

  if (classesLoading || avatarsLoading) {
    return (
      <div className='p-5'>
        <Loading />
      </div>
    );
  }

  const teacherFirstName = user?.firstName || 'Teacher';
  const teacherLastName = user?.lastName || '';
  const studentFirstName = user?.firstName || 'Student';
  const studentLastName = user?.lastName || '';

  return (
    <div data-testid='welcome' className='teacher-welcome-container py-5'>
      {signedIn && user?.teacher ? (
        <div className='container p-4'>
          <div className='welcome-message'>
            <h2>
              {t('welcomeTeacher', {
                firstName: teacherFirstName,
                lastName: teacherLastName,
              })}
            </h2>
            <p>{t('yourClassesLabel')}</p>
          </div>

          {classes && classes.length > 0 ? (
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4'>
              {classes.map((course) => {
                const students = (course.participants || []).filter((p) => p.role === 'STUDENT');
                const studentsPreview: { avatar: string; fullName: string }[] = students.map(
                  (participant) => ({
                    avatar: participant.avatar || 'default-avatar.png',
                    fullName:
                      `${participant.user.firstName || 'Unknown'} ${participant.user.lastName || ''}`.trim(),
                  }),
                );

                return (
                  <div className='col d-flex' key={course.id}>
                    <CourseCard
                      id={course.id}
                      name={course.name}
                      accessCodeBlocked={course.accessCodeBlocked}
                      accessCode={course.accessCode}
                      studentsPreview={studentsPreview}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className='mt-3'>
              <h5>{t('noClassesMessage')}</h5>
            </div>
          )}
        </div>
      ) : (
        <div className='container p-4'>
          <div className='welcome-message'>
            <h2>
              {t('welcomeStudent', {
                firstName: studentFirstName,
                lastName: studentLastName,
              })}
            </h2>
            <p>{t('yourClassesLabel')}</p>
          </div>
          {avatars && avatars.length > 0 ? (
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-4'>
              {avatars.map((avatar) => {
                // Find the corresponding course to get teacher info
                const courseInfo = classes?.find((c) => c.id === avatar.course.id);
                const teacher = courseInfo?.participants?.find((p) => p.role === 'TEACHER');

                return (
                  <AvatarCard
                    key={avatar.id}
                    avatar={avatar.avatar}
                    avatarName={avatar.avatarName}
                    course={{
                      ...avatar.course,
                      teacher: teacher
                        ? {
                            firstName: teacher.user.firstName,
                            lastName: teacher.user.lastName,
                          }
                        : undefined,
                    }}
                    xp={avatar.xp}
                    gold={avatar.gold}
                  />
                );
              })}
            </div>
          ) : (
            <div className='container mt-3'>
              <h5>{t('noAvatarsMessage')}</h5>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Welcome;
