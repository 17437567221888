import FancyTextArea from '../../../../components/FancyTextArea';
import { useConceptFormStore } from '../../../../utils/stores';
import { useForm } from 'react-hook-form';
import { FormConcept, FormConceptPart2 } from '../ConceptModal';
import { CreatedResponse, fetchAPI, UpdatedResponse } from '../../../../utils/httpRequests';
import { useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ConceptWizardStep2 = () => {
  const { t } = useTranslation('global');

  // Used to obtain values of Step 1 and 2, and save values for Step 2
  const { id, part1, part2, updatePart2, updateId } = useConceptFormStore();

  const { previousStep, nextStep } = useWizard(); // Contains step 1 and the Question part of the modal

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm<FormConceptPart2>({
    defaultValues: {
      hasDifferentiation: true,
    },
  });

  useEffect(() => {
    // Setting the values in the form to what was saved
    reset({
      hasDifferentiation: part2?.hasDifferentiation ?? true,
      differentiationLearning: part2?.differentiationLearning,
      differentiationPractice: part2?.differentiationPractice,
    });
  }, [part2, reset]);

  const differentiation = watch('hasDifferentiation');

  // Merge the 2 steps when the form is submitted
  const handleFormMerge = async (data: FormConceptPart2): Promise<void> => {
    // FIX: Update the store's part2 immediately so Step 3 sees hasDifferentiation.
    updatePart2(data);

    // Update if the concept exists, otherwise create
    if (!!part1 && !!data) {
      if (id) {
        await updateConcept({ ...part1, ...data });
      } else {
        await createConcept({ ...part1, ...data });
      }
    }
  };

  const createConcept = async (data: FormConcept) => {
    // Set default values if no differentiation
    const body = data.hasDifferentiation
      ? data
      : {
          hasDifferentiation: false,
          title: data.title,
          learning: data.learning,
          practice: data.practice,
          fileSubmissionType: data.fileSubmissionType,
        };

    const response = await fetchAPI<CreatedResponse>('/concepts', {
      method: 'POST',
      body,
    });

    if (response.isSuccess && response.data.created) {
      updateId(response.data.created);
      await nextStep();
    }
  };

  const updateConcept = async (data: FormConcept) => {
    // Set default values if no differentiation
    const body = data.hasDifferentiation
      ? data
      : {
          hasDifferentiation: false,
          title: data.title,
          learning: data.learning,
          practice: data.practice,
          fileSubmissionType: data.fileSubmissionType,
        };

    const response = await fetchAPI<UpdatedResponse>(`/concepts/${id}`, {
      method: 'PUT',
      body,
    });

    if (response.isSuccess && response.data.updated) {
      await nextStep();
    }
  };

  // Save Step 2 and go back to Step 1
  const handlePreviousStep = (): void => {
    updatePart2(getValues());
    previousStep();
  };

  // If the students fail the first part of the quest (step 1) they will need to do a step 2 if the teacher requires it
  return (
    <form onSubmit={handleSubmit(handleFormMerge)} data-testid='conceptWizardStep2'>
      <div className='mb-3'>
        <div className='form-check form-switch'>
          <input
            className='form-check-input'
            type='checkbox'
            role='switch'
            id='conceptHasDifferentiation'
            {...register('hasDifferentiation')}
          />
          <label className='form-check-label' htmlFor='conceptHasDifferentiation'>
            {t('activateDifferentiation')}
          </label>
        </div>
      </div>

      {differentiation && (
        <>
          {/* Course material section */}
          <div className='mb-3'>
            <label htmlFor='conceptDifferentiationLearning' className='form-label'>
              {t('differentiationLearning')}
            </label>
            <FancyTextArea<FormConceptPart2>
              id='conceptDifferentiationLearning'
              defaultValue={''}
              name='differentiationLearning'
              required={true}
              control={control}
              className={`${errors.differentiationLearning ? 'is-invalid' : ''}`}
            />
            {errors.differentiationLearning && (
              <span className='invalid-feedback'>{t('differentiationLearning.required')}</span>
            )}
          </div>

          {/* Practice section */}
          <div className='mb-3'>
            <label htmlFor='conceptDifferentiationPractice' className='form-label'>
              {t('differentiationPractice')}
            </label>
            <FancyTextArea<FormConceptPart2>
              id='conceptDifferentiationPractice'
              defaultValue={''}
              name='differentiationPractice'
              required={false}
              control={control}
            />
          </div>
        </>
      )}
      <div className='d-flex gap-2 mb-3'>
        <button type='button' className='btn btn-secondary' onClick={handlePreviousStep}>
          <FontAwesomeIcon icon={faArrowLeft} className='me-1' /> {t('previousStep')}
        </button>
        <button type='submit' className='btn btn-primary'>
          {id ? t('concept.edit') : t('concept.create')} {t('andProceedToQuestions')}{' '}
          <FontAwesomeIcon icon={faArrowRight} className='ms-1' />
        </button>
      </div>
    </form>
  );
};

export default ConceptWizardStep2;
